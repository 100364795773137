import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import '../styles/login.css'

const Login = () => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginResponse, setLoginResponse] = useState([]);

  const navigate = useNavigate();

  async function loginFunction(e) {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API_HOST}/user/login`, {
      username: username,
      password: password
    }).then((resp) => {
      setLoginResponse(resp.data);
      if(resp.data.ok === true)
        return navigate('/dashboard');
    })
  }

  return (
    <div className="login-page">
        <div className="header"><h1>Prihlásenie</h1></div>
        <div className="login-form">
        <form method="POST" onSubmit={(e)=>loginFunction(e)}>
                <label for="fname">Prihlasovacie meno</label>
                <input type="text" onChange={(e)=>setUsername(e.target.value)} id="fname" name="firstname" placeholder="napr. uzivatel158"/>

                <label for="lname">Heslo</label>
                <input type="password" onChange={(e)=>setPassword(e.target.value)} id="lname" name="lastname" placeholder="napr. mrkvicka123"/>
                <p>Po prihlásení budete presmerovaní do administračného panelu. Toto prihlasovanie neslúži pre uživateľov.</p>
                <p style={{color: 'lightcyan'}}>{loginResponse.message}</p>
                <div style={{textAlign: 'center'}}><input type="submit" value="Prihlásiť sa"/></div>
            </form>
        </div>
    </div>
  )
}

export default Login