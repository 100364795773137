import React, { useEffect, useState } from 'react'
import '../styles/archive.css'
import { getEvents } from '../functions/getEvents';
import { filterEvents } from '../functions/filterEvents';
import { fixedDate } from '../functions/dates';
import { useNavigate, useParams } from 'react-router-dom';

const Archive = () => {

    const [eventsOld, setEventsOld] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        async function OnStart() {
          const eventData = await getEvents();
          const filteredOld = filterEvents(eventData.data, 1, eventData.data.length);
          setEventsOld(filteredOld);
        }
        OnStart();
      }, []);

  return (
    <div style={{paddingBottom: '50px'}} className="archive">
        <h1>Archív podujatí</h1>
        <div className="events">
            {eventsOld.length === 0 ? <p style={{color: 'gray', textAlign: 'center'}}>Žiadne archivované podujatia nie sú zatiaľ k dispozícii!</p> 
            : eventsOld.map((event, index) =>               
              <div key={index} className="event-item">
                <img src={process.env.REACT_APP_API_HOST + "/static/" + event.photos[0]} alt="test"/>
                <div className="archive-item-info">
                  <h2 style={{marginBottom: '0px'}}>{event.header}</h2>
                  <small style={{color: 'gray'}}>Dátum konania: {fixedDate(event.startDate)}</small><br/>
                  <button onClick={()=> navigate(`../archive-item/${event.id}`)}>Zobraziť viac</button>
                </div>
              </div>)}
        </div>
    </div>
  )
}

export default Archive