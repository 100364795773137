import './App.css'
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Footer from './components/Footer'
import axios from 'axios'
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Cameras from './pages/Cameras';
import Archive from './pages/Archive';
import ArchiveItem from './pages/ArchiveItem';
import Events from './pages/Events';
import EventItem from './pages/EventItem';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Cennik from './pages/Cennik';

function App() {

  axios.defaults.withCredentials = true;
  const [isMobile, setIsMobile] = useState(false);
  
  var kontaktRef = useRef();
  var cennikRef = useRef();
  var instruktoriRef = useRef();
  var uvodRef = useRef();

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize)
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 600) {
        setIsMobile(true);
    } else {
        setIsMobile(false);
    }
  }

  return (
    !isMobile ? <div className="App" style={{maxWidth: 'calc(100% - 400px)'}}>
      <Router>
        <Navbar kontaktRef={kontaktRef} cennikRef={cennikRef} instruktoriRef={instruktoriRef} uvodRef={uvodRef} />
          <Routes>
            <Route path="/" element={<Home kontaktRef={kontaktRef} cennikRef={cennikRef} uvodRef={uvodRef} instruktoriRef={instruktoriRef} /> } />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/cameras' element={<Cameras/>} />
            <Route path='/archive' element={<Archive/>} />
            <Route path='/archive-item/:id' element={<ArchiveItem/>} />
            <Route path='/events' element={<Events/>} />
            <Route path='/event-item/:id' element={<EventItem/>} />
          </Routes>
        <Footer/>
      </Router>
    </div> : <div className="App">
      <Router>
        <Navbar />
          <Routes>
            <Route path="/" element={<Home /> } />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/cameras' element={<Cameras/>} />
            <Route path='/archive' element={<Archive/>} />
            <Route path='/archive-item/:id' element={<ArchiveItem/>} />
            <Route path='/events' element={<Events/>} />
            <Route path='/event-item/:id' element={<EventItem/>} />
          </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
