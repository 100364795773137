import React, { useEffect, useReducer, useState } from 'react'
import '../styles/navbar.css'
import { useSpring, useTransition, animated } from 'react-spring'
import { Link } from "react-router-dom";
import logo from '../static/pictures/logo.png';
import question from '../static/pictures/question.png';
import { getCameraPics } from '../functions/getCameraPics';
import { seasonChecking } from '../functions/seasonChecking';
import Otvorenie from '../components/Otvorenie.js';
import { HashLink } from 'react-router-hash-link';

const Sidebar = ({ isMobile, setOpenSideBar, openSideBar }) => {

    const [cameraPics, setCameraPics] = useState("");
    const [imgCamera, setImgCamera] = useState();
    const [imgCameraTwo, setImgCameraTwo] = useState();
    const [zoom, setZoom] = useState(0);

    const isSummer = seasonChecking();




    const fetchCameraData = async () => {
        try {
            const cameraData = await getCameraPics();
            setCameraPics(cameraData);

            if (cameraData.length === 0) {
                console.warn('No camera data available.');
                return;
            }

            const url = process.env.REACT_APP_API_HOST + '/static/webp/' + cameraData[0];

            const res = await fetch(url);
            if (!res.ok) {
                throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
            }

            const imageBlob = await res.blob();
            const imageObjectURL = URL.createObjectURL(imageBlob);
            setImgCamera(imageObjectURL);

            console.log('Image loaded successfully');
        } catch (error) {
            console.error('Error fetching image:', error);
            // Handle errors, e.g., retry or display a placeholder image
        }
    };

    useEffect(() => {
        const loadData = async () => {
            await fetchCameraData();
        };

        // Initial load
        loadData();

        // Cameras refresh every 10 seconds
        const intervalId = setInterval(loadData, 10000);

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    const transition = useTransition(openSideBar, {
        from: { right: '-800px' },
        enter: { right: '0px' },
        leave: { right: '-800px' }
    });

    return transition((style, item) => {
        return !isMobile ? <>

            {zoom === 1 ? <div className="zoom">
                <img style={{ cursor: 'pointer' }} onClick={() => setZoom(0)} src={imgCamera} />
                <p></p>
            </div> : ""}

            <div className="sidenav">
                <div style={isSummer ? { paddingBottom: '30px', color: '#84d9ad' } : { paddingBottom: '30px', color: 'aqua' }} className="daily-header">Záznam z kamery</div>

                <div className="zaznamy-kamier" style={{ justifyContent: 'left', marginLeft: '30px' }}>
                    <img style={{ cursor: 'pointer' }} onClick={() => setZoom(1)} src={imgCamera} />
                </div>

                <div style={isSummer ? { paddingBottom: '30px', color: '#84d9ad' } : { paddingBottom: '30px', color: 'aqua' }} className="daily-header">Otváracie hodiny</div>
                <Otvorenie></Otvorenie>
                <div className="bot">
                    <img src={logo} alt="logo" />
                </div>
            </div></> : item && isMobile ? <animated.div style={style} className="sidenav">
                <button onClick={() => setOpenSideBar(false)} id="hide">X</button>
                <a onClick={() => setOpenSideBar(false)} href="https://skiland.sk/">Úvod</a>
                {/*  <Link onClick={() => setOpenSideBar(false)} to='/archive'>Archív</Link> */}
                <Link onClick={() => setOpenSideBar(false)} to='/events'>Podujatia</Link>
                <Link onClick={() => setOpenSideBar(false)} to='/cameras'>Záznamy kamier</Link>
                <HashLink onClick={() => setOpenSideBar(false)} to='/#cennik-id'>Cenník</HashLink>
                <div style={{ paddingBottom: '30px' }} className="daily-header">Otváracie hodiny</div>
                <Otvorenie></Otvorenie>
                <div className="bot">
                    <img src={logo} alt="logo" />
                </div>
            </animated.div> : ""
    });
}

export default Sidebar